import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/recipemenus`} component={lazy(() => import(`./recipe-menu`))} />
        <Route path={`${APP_PREFIX_PATH}/caterers`} component={lazy(() => import(`./caterers`))} />
        
         <Route path={`${APP_PREFIX_PATH}/masters`} component={lazy(() => import(`./masters`))} />
         <Route path={`${APP_PREFIX_PATH}/quotes-request`} component={lazy(() => import(`./quotesrequest`))} />
          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
