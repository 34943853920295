import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
  })

const authService = {}



authService.signInEmailRequest = function(email, password) {
    const postData = {
        email,
        password,
        type: 'jwt',
    };
    return service.post(`login`,
        postData,
    )
    .then(res => res.data).catch(err => err);
}

export default authService